import callApi from '../../actions/callApi';
import { SET_FILTERS, CLEAR_FILTERS } from '../../actions/filters';

export default ({ dispatch }) => (next) => (action) => {
  const result = next(action);

  const { type, entity, fetchParams } = action;
  if ([SET_FILTERS, CLEAR_FILTERS].includes(type)) dispatch(callApi(entity, fetchParams));

  return result;
};
