import { usedLanguages } from '../locales';
import LoggedOutLayout from '../layouts/LoggedOutLayout';
import LoggedInLayout from '../layouts/LoggedInLayout';

export const BASE_PATH = `/:locale(${usedLanguages.join('|')})`;

const loggedInRoutes = [
  {
    id: 'studio',
    path: `${BASE_PATH}/:eventUri/studio/:workshopId`,
    component: 'Studio',
    extraProps: {
      isHost: true,
    },
    Layout: LoggedInLayout,
  },
  {
    id: 'guest',
    path: `${BASE_PATH}/:eventUri/guest/:workshopId`,
    component: 'Studio',
    extraProps: {
      isHost: false,
    },
    Layout: LoggedInLayout,
  },
  {
    id: 'player',
    path: `${BASE_PATH}/player`,
    component: 'Player',
    Layout: LoggedInLayout,
  },
  {
    id: 'wowza-player',
    path: `${BASE_PATH}/players/wowza`,
    component: 'WowzaPlayer',
    Layout: LoggedInLayout,
  },
  {
    id: 'speaker-chat',
    path: `${BASE_PATH}/speaker-chat`,
    component: 'SpeakersChatRoom',
    Layout: LoggedInLayout,
  },
  {
    id: 'not-found',
    path: `${BASE_PATH}/not-found`,
    component: 'Unauthorized',
    Layout: LoggedInLayout,
  },
];

const loggedOutRoutes = [
  {
    id: 'auth',
    path: `${BASE_PATH}/eventtia-sso/:ssoToken`,
    component: 'Login',
    Layout: 'div',
  },
  {
    id: 'speaker-sso-auth',
    path: `${BASE_PATH}/speaker-sso/:ssoToken`,
    component: 'SpeakerChatSSOLogin',
    Layout: 'div',
  },
  {
    id: 'speaker-login',
    path: `${BASE_PATH}/:eventUri/:workshopId/speaker-login`,
    component: 'SpeakerLogin',
    Layout: LoggedOutLayout,
  },
  {
    id: 'player',
    path: `${BASE_PATH}/player`,
    component: 'Player',
    Layout: 'div',
  },
  {
    id: 'wowza-player',
    path: `${BASE_PATH}/players/wowza`,
    component: 'WowzaPlayer',
    Layout: 'div',
  },
  {
    id: 'guest',
    path: `${BASE_PATH}/:eventUri/guest/:workshopId`,
    component: 'Studio',
    Layout: 'div',
  },
  {
    id: 'not-found',
    path: `${BASE_PATH}/not-found/:reason?`,
    component: 'Unauthorized',
    Layout: 'div',
  },
];

const routes = {
  loggedInRoutes,
  loggedOutRoutes,
};

export default routes;

export const injectParams = (path, params) => path.replace(
  /:([^/]+)/g,
  (paramExpr) => {
    const paramName = paramExpr.replace(':', '').replace(/\(.+/, '');
    return params[paramName];
  }
);
