/* eslint-disable no-param-reassign, camelcase */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isInCall: false,
  waitingParticipants: 0,
  messages: [
    // { sender: 'Mateo', message: 'Lorem ipsum dolor sit
    // amet, consectetur adipiscing elit, sed do eiusmod tempor.' },
    // { message: 'Lorem ipsum dolor sit amet, consectetur
    // adipiscing elit, sed do eiusmod tempor.' },
    // { type: 'error', sender: 'Houston, we have a problem', message:
    // 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.' },
    // { type: 'warning', sender: '[User] joined the stream' },
    // { type: 'success', sender: 'You are now live', message: 'Lorem
    // ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.' },
  ],
  livestream: {
    isLiveStreaming: false,
    isLoadingLivestream: false,
    layout: 'default',
    pinnedParticipant: null,
  },
  recording: {
    isRecording: false,
    isLoading: false,
  },
  participants: {
    local: {
      video: false,
      audio: false,
      screen: false,
      username: 'Host',
    },
  },
  room: {},
};

const studioStateSlice = createSlice({
  name: 'studio',
  initialState,
  reducers: {
    setRoom: (state, { payload: { room } }) => {
      state.room = room;
    },
    startStreaming: (state) => {
      state.livestream.isLiveStreaming = true;
      state.livestream.isLoadingLiveStream = false;
    },
    stopStreaming: (state) => {
      state.livestream.isLiveStreaming = false;
      state.livestream.isLoadingLiveStream = false;
    },
    startRecording: (state) => {
      state.recording.isRecording = true;
      state.recording.isLoading = false;
    },
    stopRecording: (state) => {
      state.recording.isRecording = false;
      state.recording.isLoading = false;
    },
    loadRecording: (state) => {
      state.recording.isLoading = true;
    },
    setStreamLoading: (state) => {
      state.livestream.isLiveStreaming = false;
      state.livestream.isLoadingLiveStream = true;
    },
    joinCall: (state) => {
      state.isInCall = true;
    },
    leaveCall: (state) => {
      state.isInCall = false;
    },
    addMessage: (state, {
      payload: {
        sender,
        message = '',
      },
    }) => {
      const newMessage = {
        type: 'message',
        sender,
        message,
      };
      state.messages = [...state.messages, newMessage];
    },
    addNotification: (state, {
      payload: {
        type,
        localeIndex,
        localeParams,
        message = '',
      },
    }) => {
      const newNotification = {
        type,
        localeIndex,
        localeParams,
        message,
      };
      state.messages = [...state.messages, newNotification];
    },
    participantLeft: (state, {
      payload: {
        sessionId,
        local,
      },
    }) => {
      const { [local ? 'local' : sessionId]: participant, ...other } = state.participants;
      state.participants = other;
    },
    participantUpdate: (state, {
      payload: {
        sessionId,
        local,
        video,
        audio,
        screen,
        username,
      },
    }) => {
      const parsedSessionId = local ? 'local' : sessionId;
      state.participants[parsedSessionId] = {
        sessionId: parsedSessionId,
        username,
        video,
        audio,
        screen,
      };
    },
    addWaitingParticipant: (state) => {
      state.waitingParticipants += 1;
    },
    removeWaitingParticipant: (state) => {
      state.waitingParticipants -= 1;
    },
    updateStreamLayout: (state, action) => {
      state.livestream.layout = action.payload.layout;
    },
    pinParticipant: (state, action) => {
      state.livestream.pinnedParticipant = action.payload.pinnedParticipant;
    },
    unpinParticipant: (state) => {
      state.livestream.pinnedParticipant = null;
    },
  },
});

export const {
  setRoom,
  startStreaming,
  stopStreaming,
  loadRecording,
  startRecording,
  stopRecording,
  setStreamLoading,
  joinCall,
  leaveCall,
  addMessage,
  addNotification,
  participantLeft,
  participantUpdate,
  addWaitingParticipant,
  removeWaitingParticipant,
  updateStreamLayout,
  pinParticipant,
  unpinParticipant,
} = studioStateSlice.actions;

export default studioStateSlice.reducer;
