import React from 'react';
import PropTypes from 'prop-types';

import SocketProvider from '../providers/SocketProvider';
import { getTokenContent } from '../helpers/auth';

const LoggedInLayout = ({ children }) => {
  const { class: tokenType } = getTokenContent();

  return (
    <SocketProvider type={tokenType}>
      {children}
    </SocketProvider>
  );
};

LoggedInLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LoggedInLayout;
