import { combineReducers } from 'redux';
import { fetchConfig } from '../../actions/callApi';

export const defaultState = {
  success: false,
  isFetching: false,
  isFirstPage: false,
  order: null,
  links: {},
  error: null,
};

export const createReducer = (types) => {
  if (!Array.isArray(types) || types.length !== 3) throw new Error('Expected types to be an array of three elements.');
  if (!types.every((type) => typeof type === 'string')) throw new Error('Expected action types to be strings.');

  const [requestType, successType, failureType] = types;

  return (state = defaultState, action) => {
    switch (action.type) {
      case requestType:
        return {
          ...state,
          success: false,
          isFetching: true,
          isFetchingFirstPage: action.endpoint !== state.links.next,
        };
      case successType: {
        const response = action.response || {};
        const { links = {}, order = [], message } = response;

        return {
          success: message || true,
          isFetching: false,
          isFetchingFirstPage: false,
          order: action.extendOrder ? state.order.concat(order) : order,
          links,
          error: null,
        };
      }
      case failureType:
        return {
          ...state,
          success: false,
          isFetching: false,
          isFetchingFirstPage: false,
          error: action.error,
        };
      default:
        return state;
    }
  };
};

const entitiesReducers = {};
Object.keys(fetchConfig).forEach((entity) => {
  entitiesReducers[entity] = createReducer(fetchConfig[entity].types);
});

export default combineReducers(entitiesReducers);
