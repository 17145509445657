import React, { useEffect, useMemo } from 'react';
import {
  useLocation, Switch, Route, Redirect, useParams,
} from 'react-router-dom';
import moment from 'moment-timezone';
import i18next from 'i18next';
import { connect } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
// import 'moment/locale/es';
// import 'moment/locale/fr';
import { loggedIn } from '../helpers/auth';
import createTheme from '../helpers/createTheme';
import CustomPropTypes from '../helpers/CustomPropTypes';
import AsyncPage from '../hocs/AsyncPage';
import useRoutes from '../hooks/useRoutes';

export const Component = ({ settings }) => {
  const { loggedInRoutes, loggedOutRoutes, urlTo } = useRoutes();
  const { pathname } = useLocation();
  const { locale } = useParams();

  useEffect(() => {
    moment.locale(locale);
    i18next.changeLanguage(locale);
  }, [locale]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const {
    primaryColor, secondaryColor, navbarColor,
  } = settings || {};
  const theme = useMemo(() => createTheme({
    primaryColor, secondaryColor, navbarColor,
  }), [primaryColor, secondaryColor, navbarColor]);

  return (
    <ThemeProvider theme={theme}>
      {loggedIn() ? (
        <Switch>
          {loggedInRoutes.map(({ path, component, Layout, extraProps = {} }) => (
            <Route exact path={path} key={component}>
              <Layout>
                <AsyncPage page={component} {...extraProps} />
              </Layout>
            </Route>
          ))}
          <Route>
            <Redirect to={urlTo('not-found')} />
          </Route>
        </Switch>
      ) : (
        <Switch>
          {loggedOutRoutes.map(({ path, component, Layout }) => (
            <Route exact path={path} key={component}>
              <Layout>
                <AsyncPage page={component} />
              </Layout>
            </Route>
          ))}

          <Route>
            <Redirect to={urlTo('not-found')} />
          </Route>
        </Switch>
      )}
    </ThemeProvider>
  );
};

Component.propTypes = {
  settings: CustomPropTypes.appSettings,
};

Component.defaultProps = {
  settings: undefined,
};

const mapStateToProps = ({
  entities: { appSettings },
}) => ({
  settings: Object.values(appSettings || {})[0],
});

export default connect(mapStateToProps)(Component);
