import normalize from 'jsonapi-normalizer';
import { decamelizeKeys, camelizeKeys, camelize } from 'humps';
import merge from 'lodash/merge';
import { CALL_API, fetchConfig } from '../../actions/callApi';
import { logout } from '../../actions/app';
import ApiError from '../../helpers/ApiError';

const [loginRequest] = fetchConfig.login.types;
const [speakerLoginRequest] = fetchConfig.speakerLogin.types;

const callApi = async (endpoint, passActionWith, types, options = {}, fetchAllPages = false) => {
  const [requestType, successType, failureType] = types;

  passActionWith({ type: requestType, endpoint });

  const newOptions = { ...options };

  if (newOptions?.headers?.['Content-Type'] === 'application/json' && newOptions.body) newOptions.body = JSON.stringify(decamelizeKeys(newOptions.body));

  let response = {};

  const fetchData = async (url) => {
    const serverResponse = await fetch(url, newOptions);
    const json = await serverResponse.json();

    if (!serverResponse.ok) throw new ApiError({
      ...json,
      status: serverResponse.status,
    });

    if (fetchAllPages) {
      const nextPage = json?.links?.next;

      if (nextPage) await fetchData(nextPage);
    }

    if (!json?.data) {
      response = json;

      return response;
    }

    const normalizedData = camelizeKeys(normalize(json));

    response = merge({}, response, normalizedData);
    response.links = json.links;

    if (json.data.length) {
      const type = camelize(json.data[0].type);

      response.order = response.order ? (
        normalizedData.result[type].concat(response.order)
      ) : (
        normalizedData.result[type]
      );
    }

    if (json.meta) response.meta = merge({}, response.meta, camelizeKeys(json.meta));

    return response;
  };

  try {
    await fetchData(endpoint);
  } catch (error) {
    return passActionWith({
      type: failureType,
      error: error.message,
      status: error.status,
    });
  }

  return passActionWith({
    response,
    type: successType,
    method: newOptions.method,
  });
};

export default (store) => (next) => (action) => {
  const callAPI = action[CALL_API];

  if (typeof callAPI === 'undefined') return next(action);

  const { types, options, fetchAllPages, passToAction } = callAPI;
  let { endpoint } = callAPI;
  if (typeof endpoint === 'function') endpoint = endpoint(store.getState());
  if (typeof endpoint !== 'string') throw new Error('Specify a string endpoint URL.');
  if (!Array.isArray(types) || types.length !== 3) throw new Error('Expected an array of three action types.');
  if (!types.every((type) => typeof type === 'string')) throw new Error('Expected action types to be strings.');

  const passActionWith = (data) => {
    const [requestType] = types;
    if (
      data.status === 401 && ![loginRequest, speakerLoginRequest].includes(requestType)
    ) return next(logout());

    let finalAction = {
      ...action,
      ...data,
    };

    if (passToAction) finalAction = {
      ...finalAction,
      ...passToAction,
    };

    delete finalAction[CALL_API];

    return next(finalAction);
  };

  return callApi(endpoint, passActionWith, types, options, fetchAllPages);
};
