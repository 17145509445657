export default {
  en: {
    actions: {
      add: 'Add',
      addToCalendar: 'Add to calendar',
      accept: 'Accept',
      addFile: 'Add file',
      addImage: 'Add image',
      clear: 'Clear',
      clone: 'Clone',
      create: 'Create',
      delete: 'Delete',
      filter: 'Filter',
      hideFilters: 'Hide filters',
      hideReactions: 'Disable Emotions',
      showReactions: 'Enable Emotions',
      remove: 'Remove',
      saveChanges: 'Save changes',
      save: 'Save',
      search: 'Search',
      cancel: 'Cancel',
      moreOptions: 'More options',
      logout: 'Log out',
      editPhoto: 'Edit Photo',
      send: 'Send',
      upload: 'Upload',
    },
    error404: {
      title: 'We couldnt find the page you were looking for',
      subtitle: 'Try another option using the sidebar.',
    },
    title: {
      announcement: 'Announcement!',
      reminder: 'Reminder',
    },
    formError: {
      enterValidEmail: 'Enter a valid email.',
      requiredField: 'The field is required.',
      invalidToken: 'The token you entered is invalid.',
      invalidEmail: 'The email you provided is invalid.',
    },
    forms: {
      notRegisteredLink: ' Register here',
      signature: 'Signature',
      firstName: 'First name',
      lastName: 'Last name',
      telephone: 'Phone',
      country: 'Country',
      jobTitle: 'Job title',
      company: 'Company',
      region: 'Region',
      city: 'City',
      photo: 'Photo',
      searchKeyword: 'Keywords',
      email: 'Email',
      accessToken: 'Access token',
      validateToken: 'Validate token',
      logIn: 'Log in',
      error: 'Error',
    },
    filters: {
      apply: 'Apply filters',
      clear: 'Clear filters',
      title: 'Filters',
      categories: {
        categoriesTypes: 'Categories types',
        all: 'All',
      },
      events: {
        datesRange: 'Date Range',
      },
      sort: {
        sortBy: 'Sort by',
        'first_name asc': 'Name A-Z↓',
        'first_name desc': 'Name Z-A↓',
        'last_name asc': 'Last name A-Z↓',
        'last_name desc': 'Last name Z-A↓',
        'company asc': 'Company A-Z↓',
        'company desc': 'Company Z-A↓',
      },
    },
    dates: {
      localTimeIs: 'Local time is',
      startDate: 'Start date',
      endDate: 'End date',
      from: 'From',
      to: 'To',
    },
    languages: {
      language: 'Language',
      en: 'English',
      es: 'Spanish',
      fr: 'French',
    },
    status: {
      title: 'Status',
      saving: 'Saving',
      completed: 'Completed',
    },
    info: {
      logoAspectRatio: 'For a better display, make sure the logo has a 16:9 aspect ratio',
    },
    speakers: {
      speakers: 'Speakers',
    },
    sponsors: {
      sponsoredBy: 'Sponsored by',
      poweredBy: 'Powered by',
    },
    timer: {
      before: 'This meeting hasn’t started yet!\nTake a seat and relax, you have {{time}} before it begins.',
      ended: 'Your meeting with {{otherParticipant}} ended {{when}}',
      remaining: 'remaining',
    },
    notes: {
      title: 'Notes',
    },
    chat: {
      title: 'Chat',
    },
    prepositions: {
      at: 'at',
    },
    qa: {
      sendAQuestion: 'Send a question...',
    },
    confirmation: {
      yes: 'Yes',
    },
    reactions: {
      reactions: 'Reactions',
    },
  },
  es: {
    actions: {
      add: 'Agregar',
      addToCalendar: 'Agregar al calendario',
      accept: 'Aceptar',
      addFile: 'Agregar un archivo',
      addImage: 'Agregar una imagen',
      clear: 'Limpiar',
      clone: 'Clonar',
      create: 'Crear',
      delete: 'Eliminar',
      filter: 'Filtrar',
      hideFilters: 'Ocultar filtros',
      hideReactions: 'Deshabilitar emociones',
      showReactions: 'Habilitar emociones',
      remove: 'Eliminar',
      saveChanges: 'Guardar cambios',
      save: 'Guardar',
      search: 'Buscar',
      cancel: 'Cancelar',
      moreOptions: 'Más opciones',
      logout: 'Cerrar sesión',
      editPhoto: 'Editar foto',
      send: 'Enviar',
      upload: 'Subir',
    },
    error404: {
      title: 'No encontramos la página que tratas de acceder.',
      subtitle: 'Intenta otra opción en la barra lateral.',
    },
    title: {
      announcement: '¡Anuncio!',
      reminder: 'Recordatorio',
    },
    formError: {
      enterValidEmail: 'Ingresa un email válido',
      requiredField: 'El campo es obligatorio.',
      invalidToken: 'El token que has ingresado es inválido.',
      invalidEmail: 'El correo ingresado es inválido.',
    },
    forms: {
      notRegisteredLink: ' Regístrate aquí',
      signature: 'Firma',
      firstName: 'Nombre',
      lastName: 'Apellido',
      telephone: 'Teléfono',
      country: 'Pais',
      jobTitle: 'Titulo laboral',
      company: 'Compañía',
      region: 'Región',
      city: 'Ciudad',
      photo: 'Foto',
      searchKeyword: 'Palabras clave',
      email: 'Correo',
      accessToken: 'Código de acceso',
      validateToken: 'Validar token',
      logIn: 'Ingresar',
      error: 'Error',
    },
    filters: {
      apply: 'Aplicar filtros',
      clear: 'Borrar filtros',
      title: 'Filtros',
      categories: {
        categoriesTypes: 'Tipos de categorias',
        all: 'Todas',
      },
      events: {
        datesRange: 'Rango de Fechas',
      },
      sort: {
        sortBy: 'Ordenar por',
        'first_name asc': 'Nombre A-Z↓',
        'first_name desc': 'Nombre Z-A↓',
        'last_name asc': 'Apellido A-Z↓',
        'last_name desc': 'Apellido Z-A↓',
        'company asc': 'Compañia A-Z↓',
        'company desc': 'Compañia Z-A↓',
      },
    },
    dates: {
      localTimeIs: 'La hora local es',
      startDate: 'Fecha de inicio',
      endDate: 'Fecha de fin',
      range: 'Rango de fechas',
      from: 'Desde',
      to: 'Hasta',
    },
    languages: {
      language: 'Idioma',
      en: 'Inglés',
      es: 'Español',
      fr: 'Frances',
    },
    status: {
      title: 'Estado',
      saving: 'Guardando',
      completed: 'Completada',
    },
    info: {
      logoAspectRatio: 'Para una mejor visualización asegúrate que el logo tenga una proporción de 16:9',
    },
    speakers: {
      speakers: 'Ponentes',
    },
    sponsors: {
      sponsoredBy: 'Patrocinado por',
      poweredBy: 'Powered by',
    },
    timer: {
      before: 'Ésta reunión aún no ha comenzado!\nToma asiento y relájate, tienes {{time}} antes de que comience',
      ended: 'Tu reunión con {{otherParticipant}} terminó {{when}}',
      remaining: 'restante',
    },
    notes: {
      title: 'Notas',
    },
    chat: {
      title: 'Chat',
    },
    prepositions: {
      at: 'en',
    },
    qa: {
      sendAQuestion: 'Envia una pregunta...',
    },
    confirmation: {
      yes: 'Si',
    },
    reactions: {
      reactions: 'Reacciones',
    },
  },
  fr: {
    actions: {
      add: 'Ajouter',
      addToCalendar: 'Ajouter au calendrier',
      accept: 'Accepter',
      addFile: 'Ajouter un fichier',
      addImage: 'Ajouter une image',
      clear: 'Effacer',
      clone: 'Cloner',
      create: 'Créer',
      delete: 'Supprimer',
      filter: 'Filtrer',
      hideFilters: 'Cacher les filtres',
      hideReactions: 'Désactiver l\'émotion',
      showReactions: 'Permettre les émotions',
      remove: 'Éliminer',
      saveChanges: 'Sauvegarder les changements',
      save: 'Sauvegarder',
      search: 'Rechercher',
      cancel: 'Annuler',
      moreOptions: 'Plus d\'options',
      logout: 'Se déconnecter',
      editPhoto: 'Éditer la photo',
      send: 'Envoyer',
      upload: 'Télécharger',
    },
    error404: {
      title: 'Nous n\'avons pas trouvé la page que vous recherchiez',
      subtitle: 'Essayez une autre option en utilisant la barre latérale.',
    },
    title: {
      announcement: 'Annonce!',
      reminder: 'Rappel',
    },
    formError: {
      enterValidEmail: 'Entrez un courriel valide',
      requiredField: 'Le champ est obligatoire.',
      invalidToken: 'Le jeton que vous avez saisi n\'est pas valable.',
      invalidEmail: 'Le courrier entré n\'est pas valide',
    },
    forms: {
      notRegisteredLink: ' Inscrivez-vous ici',
      signature: 'Signature',
      firstName: 'Prénom',
      lastName: 'Nom de famille',
      telephone: 'Téléphone',
      country: 'Pays',
      jobTitle: 'Fonction',
      company: 'Entreprise',
      region: 'Région',
      city: 'Cité',
      photo: 'Photo',
      searchKeyword: 'Mots clés',
      email: 'Email',
      accessToken: 'Code d\'accès',
      validateToken: 'Valider le code',
      logIn: 'Se connecter',
      error: 'Erreur',
    },
    filters: {
      apply: 'Appliquer les filtres',
      clear: 'Effacer les filtres',
      title: 'Filtres',
      categories: {
        categoriesTypes: 'Type de catégories',
        all: 'Tous',
      },
      events: {
        datesRange: 'Plage de dates ',
      },
      sort: {
        sortBy: 'Trier par',
        'first_name asc': 'Prénom A-Z↓',
        'first_name desc': 'Prénom Z-A↓',
        'last_name asc': 'Nom A-Z↓',
        'last_name desc': 'Nom Z-A↓',
        'company asc': 'Entreprise A-Z↓',
        'company desc': 'Entreprise Z-A↓',
      },
    },
    dates: {
      localTimeIs: 'L\'heure locale est',
      startDate: 'Date de début',
      endDate: 'Date de fin',
      range: 'Plage de dates',
      from: 'de',
      to: 'à',
    },
    languages: {
      language: '',
      en: 'Anglais',
      es: 'Espagnol',
      fr: 'Français',
    },
    status: {
      title: 'Statut',
      saving: 'Sauver',
      completed: 'Complété',
    },
    info: {
      logoAspectRatio: 'Pour une meilleure visualisation, assurez-vous que le logo a un rapport de 16:9',
    },
    speakers: {
      speakers: 'Intervenants',
    },
    sponsors: {
      sponsoredBy: 'Sponsorisé par',
      poweredBy: 'Powered by',
    },
    timer: {
      before: 'Cette réunion n\'a pas encore commencé!\nDétendez-vous, vous avez {{time}} avant de commencer',
      ended: 'Votre réunion avec {{otherParticipant}} s\'est terminée {{when}}',
      remaining: 'restant',
    },
    notes: {
      title: 'Notes',
    },
    chat: {
      title: 'Chat',
    },
    prepositions: {
      at: 'chez',
    },
    qa: {
      sendAQuestion: 'Envoyer une question...',
    },
    confirmation: {
      yes: 'Oui',
    },
    reactions: {
      reactions: 'Réactions',
    },
  },
};
