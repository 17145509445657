/* eslint camelcase: 2 */
/* eslint dot-notation: [2, {"allowPattern": "^[a-z]+(_[a-z]+)+$"}] */
import { getTokenContent } from './auth';
import parseCustomFields from './customFields';

export const getEventUri = () => window.location.pathname.split('/')[2];

export const getLocale = () => {
  const windowLocale = window.location.pathname.split('/')[1] || 'en';
  const locale = ['pt'].includes(windowLocale) ? 'es' : windowLocale; // VS-PT-HACK
  return locale;
};

export const getEntity = (entities, key) => (entities[key] && Object.values(entities[key])[0]);
export const getEntityById = (entities, key, id) => (entities[key] && entities[key][id]);

export const getCurrentAttendee = (entities, options) => {
  const eventUri = getEventUri();
  const attendeeId = getTokenContent(eventUri)?.['attendee_id'];

  let attendee = getEntityById(entities, 'attendees', String(attendeeId));

  if (options?.parseCustomFields && attendee?.fields) attendee = {
    ...attendee,
    ...parseCustomFields(attendee?.fields, entities.attendeeTypeCustomFields),
  };

  return attendee;
};

export const getEventtiaStudioWorkshop = (workshops, workshopId) => Object.values(workshops)
  ?.find(({ id }) => id === workshopId) || {};

export const getEventtiaStudioParams = (workshops, workshopId) => getEventtiaStudioWorkshop(
  workshops,
  workshopId
).eventtiaStudioParams || {};
