import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Card from 'eventtia-ui-components/lib/Card';
import Loader from 'eventtia-ui-components/lib/Loader';
import EventtiaLogo from 'eventtia-ui-components/lib/EventtiaLogo';
import FeedbackScreen from 'eventtia-ui-components/lib/FeedbackScreen';
import CustomPropTypes from '../helpers/CustomPropTypes';
import defaultLoginDisabledImage from '../assets/404.png';
import callApi from '../actions/callApi';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    minHeight: '100%',
    right: 0,
    left: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: theme.palette.common.white,
  },
  hackedRoot: {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  loginDisabledRoot: {
    backgroundColor: theme.palette.lightGrey.main,
    color: theme.palette.darkGrey.main,
    '& $pipe': {
      color: theme.palette.lightGrey.dark,
    },
    '& $poweredByContainer': {
      color: theme.palette.darkGrey.light,
      opacity: 0.6,
    },
  },
  main: {
    padding: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    position: 'relative',
  },
  container: {
    width: 542,
    maxWidth: '100%',
  },
  titleContainer: {
    marginBottom: theme.spacing(5),
    display: 'flex',
    color: theme.palette.common.white,
    alignItems: 'center',
    '& h3': {
      fontSize: 24,
      fontWeight: 'bold',
      marginBottom: 0,
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  logo: {
    maxHeight: '7em',
    maxWidth: '33%',
  },
  browserError: {
    margin: theme.spacing(2),
  },
  pipe: {
    margin: theme.spacing(0, 2),
    fontSize: theme.spacing(4),
    lineHeight: '1em',
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  name: {
    fontSize: '1.4em',
    fontWeight: 600,
  },
  poweredBy: {
    fontSize: theme.typography.body2.fontSize,
  },
  eventtia: {
    height: 13,
    width: 'auto',
    marginLeft: theme.spacing(1),
  },
  contentRoot: {
    backgroundColor: theme.palette.lightGrey.main,
    maxWidth: '100%',
    borderRadius: theme.spacing(1),
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(4),
    },
  },
  content: {
    padding: theme.spacing(6),
    minWidth: '45%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
  },
  mobileContent: {
    padding: theme.spacing(6, 0),
  },
  imageContainer: {
    marginLeft: theme.spacing(6),
    width: theme.spacing(50),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > img': {
      maxWidth: '100%',
    },
  },
  poweredByContainer: {
    textAlign: 'center',
    marginTop: theme.spacing(1.5),
    color: 'inherit',
  },
  loginDisabled: {
    width: '100%',
  },
}));

const LoggedOutLayout = ({
  children, currentEvent, settings, callApi: dispatchCallApi,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation('global');
  const { eventUri } = useParams();

  const smallMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    dispatchCallApi('settings', { eventUri });
    // if (isHost) dispatch(callApi('workshop', { eventUri, workshopId }));
    // else dispatch(callApi('workshops', { eventUri }));
  }, [dispatchCallApi, eventUri]);

  if (!currentEvent) return (
    <Loader variant="absolute" loading />
  );

  const { name, logo, whiteLabel } = currentEvent;
  const {
    enabled,
    loginDisabledImage, loginDisabledText, backgroundImage: { filename, large },
  } = settings;
  const loginTitleColor = settings.loginViewSettings?.loginTitleColor || '#FFFFFF';
  const image = filename ? large : '/default-background.jpg';
  const loginDisabledImageSrc = loginDisabledImage?.filename && loginDisabledImage.medium;
  const { filename: settingsFilename, medium: settingsMedium } = settings.logo;
  const { filename: eventFilename, medium: eventMedium } = logo;
  const style = enabled ? { backgroundImage: `url(${image})` } : undefined;

  let medium;
  if (settingsFilename) medium = settingsMedium;
  else if (eventFilename) medium = eventMedium;

  return (
    <div
      className={clsx(
        classes.root,
        eventUri === 'cbg' && classes.hackedRoot,
        !enabled && classes.loginDisabledRoot
      )}
      style={style}
    >
      <main className={classes.main}>
        <div className={classes.container}>
          <div
            className={classes.titleContainer}
          >
            {medium ? (
              <img src={medium} alt={name} className={classes.logo} />
            ) : (
              <EventtiaLogo className={classes.logo} />
            )}
            {!mobile && (
              <span className={classes.pipe} style={{ color: loginTitleColor }}>|</span>
            )}
            <div>
              <Typography
                variant={smallMobile ? 'subtitle1' : 'h3'}
                component="h3"
                style={{ color: loginTitleColor, textAlign: mobile ? 'center' : 'start' }}
              >
                {name}
              </Typography>
            </div>
          </div>

          {enabled ? (
            <Card className={classes.contentRoot}>
              <div className={clsx(classes.content, mobile && classes.mobileContent)}>
                {children}
              </div>
            </Card>
          ) : (
            <FeedbackScreen
              image={loginDisabledImageSrc || defaultLoginDisabledImage}
              alt="login disabled"
              description={loginDisabledText || t('info.loginDisabled', { event: name })}
              className={classes.loginDisabled}
            />
          )}

          {!whiteLabel && (
            <div className={classes.poweredByContainer}>
              <Typography variant="caption" className={classes.poweredBy}>
                {t('sponsors.poweredBy')}
              </Typography>
              <EventtiaLogo className={classes.eventtia} />
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

LoggedOutLayout.propTypes = {
  currentEvent: CustomPropTypes.event,
  children: PropTypes.node.isRequired,
  settings: CustomPropTypes.appSettings,
  callApi: PropTypes.func.isRequired,
};

LoggedOutLayout.defaultProps = {
  currentEvent: undefined,
  settings: undefined,
};

const mapStateToProps = ({
  entities: { events, appSettings },
}) => ({
  currentEvent: Object.values(events || {})[0],
  settings: Object.values(appSettings || {})[0],
});

export default connect(mapStateToProps, { callApi })(LoggedOutLayout);
