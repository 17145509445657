import { useCallback } from 'react';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';
import routes, { injectParams } from '../../routes';

export default () => {
  const history = useHistory();
  const { path: currentPath } = useRouteMatch();
  const params = useParams();
  const { loggedInRoutes, loggedOutRoutes } = routes;

  const pathTo = useCallback((id) => (
    loggedInRoutes.find(({ id: routeId }) => routeId === id)
    || loggedOutRoutes.find(({ id: routeId }) => routeId === id)
  ).path, [loggedInRoutes, loggedOutRoutes]);

  const urlTo = useCallback((id, paramValues = {}) => injectParams(
    pathTo(id), { ...params, ...paramValues }
  ), [pathTo, params]);

  const goTo = useCallback((id, paramValues) => history.push(
    urlTo(id, paramValues)
  ), [urlTo, history]);

  const getRouteId = useCallback(() => (
    loggedInRoutes.find(({ path }) => path === currentPath).id
    || loggedOutRoutes.find(({ path }) => path === currentPath).id
  ), [currentPath, loggedInRoutes, loggedOutRoutes]);

  return {
    goTo,
    pathTo,
    urlTo,
    goBack: history.goBack,
    getRouteId,
    loggedInRoutes,
    loggedOutRoutes,
  };
};
