import humps from 'humps';
import { getLocale } from './getters';

const endpoints = {
  login: 'sso-auth',
  speakerSsoLogin: 'speaker-sso',
  speakerAccessToken: ({ eventUri }) => `events/${eventUri}/speakers/studio-access-token`,
  speakerLogin: ({ eventUri }) => `events/${eventUri}/speakers/studio-auth`,
  settings: ({ eventUri }) => `events/${eventUri}/app_settings`,
  workshop: ({ eventUri, workshopId }) => `events/${eventUri}/workshops/${workshopId}`,
  workshops: ({ eventUri }) => `events/${eventUri}/workshops`,
  dailyOwnerToken: ({ eventUri, workshopId }) => `events/${eventUri}/workshops/${workshopId}/daily-admin-token`,
};

const appendFilters = (endpoint, filters) => {
  if (!filters || Object.keys(filters).length === 0) return endpoint;

  const connector = endpoint.includes('?') ? '&' : '?';
  const query = Object.keys(filters)
    .filter((f) => (typeof filters[f] !== 'undefined' && filters[f] !== null))
    .map((filter) => `${encodeURIComponent(humps.decamelize(filter))}=${encodeURIComponent(filters[filter])}`)
    .join('&');

  return `${endpoint}${connector}${query}`;
};

// const [defaultHost, defaultSocket] = ['http://localhost:3002', 'wss://dev-iridium.eventtia.com/socket'];

const [defaultHost, defaultSocket] = [
  process.env.REACT_APP_EVENTTIA_HOST || 'https://connect.eventtia.com',
  process.env.REACT_APP_SOCKET_URL || 'wss://iridium.eventtia.com',
];

// const [defaultHost, defaultSocket] = [
//   'https://dev-fg.eventtia.com',
//   'wss://dev-iridium.eventtia.com', // wss://iridium-dev.eventtia.com
// ];

// const [defaultHost, defaultSocket] = [
//   'https://stage-fg.eventtia.com',
//   'wss://stage-iridium.eventtia.com', // wss://iridium-dev.eventtia.com
// ];

// const [defaultHost, defaultSocket] = ['https://dev.eventtia.com', 'wss://dev-iridium.eventtia.com/socket'];
// const [defaultHost, defaultSocket] = ['https://stage.eventtia.com', 'wss://stage-iridium.eventtia.com'];

export const userSocketUrl = `${defaultSocket}/user-socket`;
export const publicSocketUrl = `${defaultSocket}/public-socket`;
export const speakerSocketUrl = `${defaultSocket}/speaker-socket`;

export default (name, params, filters) => {
  const locale = getLocale();
  const endpoint = typeof endpoints[name] === 'function' ? endpoints[name](params) : endpoints[name];
  const endpointWithFilters = appendFilters(endpoint, filters);
  return `${defaultHost}/${locale}/api/v3/${endpointWithFilters}`;
};
