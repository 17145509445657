import merge from 'lodash/merge';
// import moment, { baseMoment } from '../../helpers/dates';
import getEndpoint from '../../helpers/endpoints';
import { getToken } from '../../helpers/auth';
import { getEventUri } from '../../helpers/getters';
// import constants from '../../helpers/constants';

export const CALL_API = 'Call API';

export const fetchConfig = {
  login: {
    types: ['LOGIN_REQUEST', 'LOGIN_SUCCESS', 'LOGIN_FAILURE'],
    options: ({ token }) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: { token },
    }),
  },
  speakerSsoLogin: {
    types: ['SPEAKER_SSO_LOGIN_REQUEST', 'SPEAKER_SSO_LOGIN_SUCCESS', 'SPEAKER_SSO_LOGIN_FAILURE'],
    options: ({ token }) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: { token },
    }),
  },
  speakerAccessToken: {
    types: ['SPEAKER_ACCESS_TOKEN_REQUEST', 'SPEAKER_ACCESS_TOKEN_SUCCESS', 'SPEAKER_ACCESS_TOKEN_FAILURE'],
    options: ({ email }) => ({
      headers: {
        Accept: undefined,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: { email },
    }),
  },
  speakerLogin: {
    types: ['SPEAKER_LOGIN_REQUEST', 'SPEAKER_LOGIN_SUCCESS', 'SPEAKER_LOGIN_FAILURE'],
    options: ({ email, accessToken }) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: { accessToken, email },
    }),
  },
  settings: {
    types: ['SETTINGS_REQUEST', 'SETTINGS_SUCCESS', 'SETTINGS_FAILURE'],
    auth: false,
  },
  workshop: {
    types: ['WORKSHOP_REQUEST', 'WORKSHOP_SUCCESS', 'WORKSHOP_FAILURE'],
  },
  workshops: {
    types: ['WORKSHOPS_REQUEST', 'WORKSHOPS_SUCCESS', 'WORKSHOPs_FAILURE'],
  },
  dailyOwnerToken: {
    types: [
      'DAILY_OWNER_TOKEN_REQUEST',
      'DAILY_OWNER_TOKEN_SUCCESS',
      'DAILY_OWNER_TOKEN_FAILURE',
    ],
    options: ({ username }) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: { username },
    }),
  },
};

const getDefaultOptions = (eventUri, { auth = true }) => {
  const token = getToken(eventUri);
  const defaultOptions = {
    headers: {
      Accept: 'application/json',
    },
  };
  if (token && auth) defaultOptions.headers.Authorization = `bearer ${token}`;
  return defaultOptions;
};

export default (key, params, page) => (dispatch, getState) => {
  const config = fetchConfig[key];
  const [, successType] = config.types;
  const state = getState();
  const filters = state.filters[key];

  // return success if fetch should be skipped
  if (typeof config.skip === 'function' && config.skip(params)) return { type: successType };

  const filterParams = config.parseFilters ? config.parseFilters(filters) : filters;

  let { options, passToAction } = config;
  if (typeof options === 'function') options = options(params);
  if (typeof passToAction === 'function') passToAction = passToAction(params);

  const requestDetails = {
    types: config.types,
    endpoint: (config.fetchAllPages || !page) ? getEndpoint(key, params, filterParams) : page,
    passToAction: passToAction || {},
    options: merge(getDefaultOptions(getEventUri(), config), options),
  };

  if (config.fetchAllPages) requestDetails.fetchAllPages = true;
  else if (page) requestDetails.passToAction.extendOrder = true;

  return dispatch({
    [CALL_API]: requestDetails,
  });
};
