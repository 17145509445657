import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Socket } from 'phoenix';
import { getToken, getTokenContent } from '../../helpers/auth';
import {
  userSocketUrl,
  publicSocketUrl,
  speakerSocketUrl,
} from '../../helpers/endpoints';
import SocketContext from '../../contexts/SocketContext';

const sockets = {
  User: userSocketUrl,
  Speaker: speakerSocketUrl,
  Public: publicSocketUrl,
};

const SocketProvider = ({ children, type }) => {
  const socketUrl = sockets[type];

  const params = useMemo(() => {
    switch (type) {
      case 'User':
        return {
          params: {
            token: getToken(),
          },
        };
      // TODO ajustar esto para speaker cuando haya token de speaker y quitar lo de attendee
      case 'Speaker': {
        const { speaker_name: username } = getTokenContent();
        return {
          params: {
            token: getToken(),
            username,
            avatar: undefined,
            single_session: true,
          },
        };
      }
      default:
        return undefined;
    }
  }, [type]);

  const socket = useMemo(() => (
    new Socket(socketUrl, params)
  ), [socketUrl, params]);

  useEffect(() => {
    if (socket) socket.connect();
    return () => {
      if (socket) socket.disconnect();
    };
  }, [socket]);

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};

SocketProvider.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['User', 'Speaker', 'Attendee', 'Public']).isRequired,
};

export default SocketProvider;
