/* eslint-disable global-require */
import { configureStore } from '@reduxjs/toolkit';

import entitiesReducer from '../reducers/entities';
import metaReducer from '../reducers/meta';
import fetchStatusReducer from '../reducers/fetchStatus';
import filtersReducer from '../reducers/filters';
import studioStateReducer from '../reducers/studioState';
import speakerStateReducer from '../reducers/speakerState';

import filterMiddleware from '../middlewares/filters';
import apiMiddleware from '../middlewares/api';
import authMiddleware from '../middlewares/auth';

const store = configureStore({
  reducer: {
    entities: entitiesReducer,
    meta: metaReducer,
    fetchStatus: fetchStatusReducer,
    filters: filtersReducer,
    studio: studioStateReducer,
    speaker: speakerStateReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .concat(filterMiddleware, apiMiddleware, authMiddleware),
  devTools: {
    trace: true,
  },
});

export default store;
