export default {
  en: {
    statusWaiting: {
      intro: 'Please wait,',
      highlighted: 'the stream is starting soon!',
    },
    statusEnded: {
      intro: 'Thank you for watching,',
      highlighted: 'this stream has ended!',
    },
  },
  es: {
    statusWaiting: {
      intro: 'Por favor espera,',
      highlighted: 'la transmisión comenzará en breve!',
    },
    statusEnded: {
      intro: 'Gracias por ver,',
      highlighted: 'la transmisión ha terminado.',
    },
  },
  fr: {
    statusWaiting: {
      intro: 'Veuillez patienter,',
      highlighted: 'la transmission démarrera dans quelques instants',
    },
    statusEnded: {
      intro: 'Merci pour votre visionnage,',
      highlighted: 'la transmission est terminée !',
    },
  },
  de: {
    statusWaiting: {
      intro: 'Bitte warten Sie,',
      highlighted: 'der Stream beginnt bald!',
    },
    statusEnded: {
      intro: 'Vielen Dank für Ihr Interesse,',
      highlighted: 'dieser Stream ist beendet!',
    },
  },
  pt: {
    statusWaiting: {
      intro: 'Por favor aguarde,',
      highlighted: 'a transmissão está a começar em breve!',
    },
    statusEnded: {
      intro: 'Obrigado por assistir,',
      highlighted: 'esta transmissão terminou!',
    },
  },
};
