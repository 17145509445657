import { mergeLocales } from 'eventtia-ui-components/lib/locales';
import buttons from 'eventtia-ui-components/lib/locales/buttons';
import currency from 'eventtia-ui-components/lib/locales/currency';
import global from './global';
import studio from './studio';
import player from './player';
import notifications from './notifications';

const USED_LANGUAGES = ['en', 'es', 'fr'];
export { USED_LANGUAGES as usedLanguages };

export default mergeLocales({
  buttons,
  currency,
  global,
  studio,
  player,
  notifications,
}, USED_LANGUAGES);
