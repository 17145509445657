import { createMuiTheme } from '@material-ui/core/styles';
import { rawTheme } from 'eventtia-ui-components/lib/styles/theme';
import cloneDeep from 'lodash/cloneDeep';

const createTheme = ({ primaryColor, secondaryColor, navbarColor }) => {
  const newRawTheme = cloneDeep(rawTheme);
  newRawTheme.palette.primary = {
    main: primaryColor || rawTheme.palette.primary.main,
  };
  newRawTheme.palette.secondary = {
    main: secondaryColor || rawTheme.palette.secondary.main,
  };
  newRawTheme.palette.navbar = {
    main: navbarColor || rawTheme.palette.lightGrey.main,
  };
  newRawTheme.subpageDrawer = {
    paddings: {
      desktop: { vertical: 4, horizontal: 6.5 },
      mobile: { vertical: 3, horizontal: 3.5 },
    },
  };
  return createMuiTheme(newRawTheme);
};

export default createTheme;
