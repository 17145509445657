const TOKEN_KEY = 'evt-studio-token';

export const setToken = (token) => sessionStorage.setItem(TOKEN_KEY, token);

export const removeToken = () => sessionStorage.removeItem(TOKEN_KEY);

export const loggedIn = () => !!sessionStorage.getItem(TOKEN_KEY);

export const getToken = () => sessionStorage.getItem(TOKEN_KEY);

export const getTokenContent = () => {
  const token = sessionStorage.getItem(TOKEN_KEY);
  if (!token) return {};
  const [, payload] = token.split('.');

  return JSON.parse(atob(payload));
};
