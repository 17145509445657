export default {
  en: {
    liveStreaming: {
      start: 'Go Live',
      stop: 'Stop',
      disabled: 'Disabled',
    },
    streamControls: {
      leaveCall: 'Leave call',
      eject: 'Kick',
      video: {
        on: 'Turn off',
        off: 'Turn on',
      },
      audio: {
        on: 'Mute',
        off: 'Unmute',
      },
      screen: {
        on: 'Stop',
        off: 'Share screen',
      },
      highlighted: 'Highlight',
      addUser: {
        label: 'Add user',
        description: 'To invite someone to this meeting, share this link with them',
      },
      recording: {
        on: 'Stop recording',
        off: 'Start recording',
        loading: 'Initializing...',
      },
    },
    systemMessages: {
    },
    chat: {
      title: 'Chat',
      speakerTitle: 'Speaker chat',
      noMessages: 'There are no messages in this chat yet.',
      description: 'This chat is only visible for the paricipants in this meeting',
      speakerChatDescription: 'This chat is only visible for the speakers of the workshop',
      makeMessage: 'Be the first to send a message!',
      inputPlaceholder: 'Type message...',
    },
    actions: {
      copyToClipboard: 'Copy to clipboard',
    },
    participants: {
      noParticipants: 'We\'ve noticed it\'s just you in the studio. Want to invite someone?',
    },
    streamLayouts: {
      description: 'Select a stream layout',
      helper: 'Stream layouts let you customize how viewers will see the stream',
    },
  },
  es: {
    liveStreaming: {
      start: 'Comenzar',
      stop: 'Detener',
      disabled: 'Deshabilitado',
    },
    streamControls: {
      leaveCall: 'Dejar llamada',
      eject: 'Expulsar',
      video: {
        on: 'Desactivar',
        off: 'Activar',
      },
      audio: {
        on: 'Silenciar',
        off: 'Activar',
      },
      screen: {
        on: 'Detener',
        off: 'Compartir',
      },
      highlighted: 'Destacar',
      addUser: {
        label: 'Invitar',
        description: 'Para invitar a alguien al estudio, compartele este link',
      },
      recording: {
        on: 'Detener grabación',
        off: 'Comenzar grabación',
        loading: 'Inicializando...',
      },
    },
    systemMessages: {
    },
    chat: {
      title: 'Chat',
      speakerTitle: 'Chat de ponentes',
      noMessages: 'Aún no hay mensajes en este chat.',
      description: 'Este chat solo está disponible para participantes en el estudio',
      speakerChatDescription: 'Este chat solo es visible para los ponentes del taller',
      makeMessage: 'Sé el primero en enviar uno!',
      inputPlaceholder: 'Escribe un mensaje...',
    },
    actions: {
      copyToClipboard: 'Copiar',
    },
    participants: {
      noParticipants: 'Estás solo en el estudio. ¿Quieres invitar a alguien?',
    },
    streamLayouts: {
      description: 'Selecciona una plantilla de transmisión',
      helper: 'Las plantillas te permiten personalizar la manera en la que los espectadores ven la transmisión',
    },
  },
  fr: {
    liveStreaming: {
      start: 'Lancer',
      stop: 'Arrêter',
      disabled: 'Désactivé',
    },
    streamControls: {
      leaveCall: 'Quitter l\'appel',
      eject: 'Rejeter',
      video: {
        on: ' Arrêter',
        off: 'Mettre en route',
      },
      audio: {
        on: 'Couper',
        off: 'Activer',
      },
      screen: {
        on: 'Arrêter',
        off: 'Partage d\'écran',
      },
      highlighted: 'Surligner',
      addUser: {
        label: 'Ajouter un utilisateur',
        description: 'Pour inviter quelqu\'un à cette réunion, transmettez-lui ce lien.',
      },
      recording: {
        on: 'Arrêter l\'enregistrement',
        off: 'Démarrer l\'enregistrement',
        loading: 'Initialisation...',
      },
    },
    systemMessages: {
    },
    chat: {
      title: 'Chat',
      speakerTitle: 'Conversation avec le conférencier',
      noMessages: 'Il n\'y a pas encore de messages dans ce chat.',
      description: 'Ce chat est uniquement accessible aux participants de cette conversation',
      speakerChatDescription: 'Ce chat n\'est visible que pour les intervenants de l\'atelier',
      makeMessage: 'Soyez le premier à envoyer un message !',
      inputPlaceholder: 'Écrire un message...',
    },
    actions: {
      copyToClipboard: 'Copier',
    },
    participants: {
      noParticipants: 'Vous êtes seul(e) dans ce groupe, voulez-vous inviter quelqu\'un ?',
    },
    streamLayouts: {
      description: 'Choisir un modèle de diffusion',
      helper: 'Les modèles vous permettent de personnaliser la mfaçon dont les utilisateurs visualiseront la vidéo',
    },
  },
};
