/* eslint-disable no-param-reassign, camelcase */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  messages: [],
};

const speakerStateSlice = createSlice({
  name: 'speaker',
  initialState,
  reducers: {
    addSpeakerMessage: (state, { payload }) => {
      const newMessage = {
        type: 'message',
        ...payload,
      };
      state.messages = [...state.messages, newMessage];
    },
    addSpeakerPreviousMessages: (state, { payload }) => {
      state.messages = payload;
    },
  },
});

export const {
  addSpeakerMessage,
  addSpeakerPreviousMessages,
} = speakerStateSlice.actions;

export default speakerStateSlice.reducer;
