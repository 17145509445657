import React from 'react';
import ReactDOM from 'react-dom';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { Provider as ReduxProvider } from 'react-redux';
import {
  BrowserRouter, Switch, Route, Redirect,
} from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import momentUtils from '@date-io/moment';
import CssBaseline from '@material-ui/core/CssBaseline';
import locales from './locales';
import store from './store';
import App from './App';
import { BASE_PATH } from './routes';

i18n.use(initReactI18next).init({
  resources: {
    ...locales,
  },
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

ReactDOM.render(
  <ReduxProvider store={store}>
    <BrowserRouter>
      <MuiPickersUtilsProvider utils={momentUtils}>
        <CssBaseline />

        <Switch>
          <Route path={BASE_PATH}>
            <App />
          </Route>

          <Redirect exact from="/" to="/en" />
        </Switch>
      </MuiPickersUtilsProvider>
    </BrowserRouter>
  </ReduxProvider>,
  document.getElementById('root')
);
