import PropTypes from 'prop-types';

const reaction = PropTypes.shape({
  emoji: PropTypes.string,
  name: PropTypes.string,
  uuid: PropTypes.string,
});

const event = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  eventUri: PropTypes.string,
  whiteLabel: PropTypes.bool,
});

const appSettings = PropTypes.shape({
  id: PropTypes.string,
  darkTheme: PropTypes.bool,
  waitingImage: PropTypes.shape({
    filename: PropTypes.string,
    large: PropTypes.string,
  }),
  languageSelectorEnabled: PropTypes.bool,
  backgroundImage: PropTypes.shape({
    filename: PropTypes.string,
    large: PropTypes.string,
  }),
  icon: PropTypes.shape({
    filename: PropTypes.string,
    thumb: PropTypes.string,
  }),
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  logo: PropTypes.shape({
    filename: PropTypes.string,
    medium: PropTypes.string,
    small: PropTypes.string,
  }),
  modules: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.shape({
      en: PropTypes.string,
      es: PropTypes.string,
      fr: PropTypes.string,
    }),
    type: PropTypes.string,
    enabled: PropTypes.bool,
    customParams: PropTypes.shape({
      content: PropTypes.string,
      icon: PropTypes.string,
    }),
  })),
});

const meeting = PropTypes.shape({
  id: PropTypes.string,
  guid: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  host: PropTypes.shape({
    id: PropTypes.string,
  }),
  participant: PropTypes.shape({
    id: PropTypes.string,
  }),
  businessConferenceId: PropTypes.number,
  status: PropTypes.number,
  userMessage: PropTypes.string,
});

const workshop = PropTypes.shape({
  id: PropTypes.string,
  guid: PropTypes.string,
  name: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  description: PropTypes.string,
  streamingType: PropTypes.string,
  streamingUrl: PropTypes.string,
  streamingEmbedCode: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      en: PropTypes.string,
      es: PropTypes.string,
      fr: PropTypes.string,
    }),
    PropTypes.arrayOf(PropTypes.shape({
      displayName: PropTypes.string,
      embedCode: PropTypes.string,
    })),
  ]),
  speakers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
  enabledEmotions: PropTypes.bool,
});

const workshopCategory = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
});

const speaker = PropTypes.shape({
  fullName: PropTypes.string,
  company: PropTypes.string,
  position: PropTypes.string,
  picture: PropTypes.shape({
    filename: PropTypes.string,
    small: PropTypes.string,
  }),
});

const formErrors = PropTypes.objectOf(PropTypes.shape({
  type: PropTypes.string,
  message: PropTypes.string,
  ref: PropTypes.shape({
    type: PropTypes.string,
  }),
}));

const entities = PropTypes.objectOf(
  PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  )
);

const meta = PropTypes.objectOf(
  PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
    PropTypes.shape({}),
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.number,
        PropTypes.string,
        PropTypes.shape({}),
      ])
    ),
  ])
);

export default {
  appSettings,
  entities,
  event,
  meeting,
  meta,
  reaction,
  speaker,
  workshop,
  workshopCategory,
  formErrors,
};
