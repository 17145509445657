export default {
  en: {
    streaming: {
      started: 'Live stream has started',
      stopped: 'Live stream has stopped',
      error: 'Live stream error',
    },
    call: {
      users: {
        joined: '[{{username}}] has joined the stream',
        left: '[{{username}}] has left the stream',
      },
    },
    recording: {
      started: 'Recording has started',
      stopped: 'Recording has stopped',
      error: 'Couldn\'t start recording. Please try again in a moment.',
    },
  },
  es: {
    streaming: {
      started: 'La transmisión ha comenzado',
      stopped: 'La transmisión se ha detenido.',
      error: 'Error en la transmisión.',
    },
    call: {
      users: {
        joined: '[{{username}}] se ha unido a la transmisión.',
        left: '[{{username}}] ha dejado la transmisión.',
      },
    },
    recording: {
      started: 'La grabación ha comenzado',
      stopped: 'La grabación se ha detenido.',
      error: 'No se pudo iniciar la grabación. Inténtalo de nuevo en un momento.',
    },
  },
  fr: {
    streaming: {
      started: 'La transmission en direct a démarré.',
      stopped: 'La transmission en direct s\'est arrêté.',
      error: 'Erreur de transmission en direct.',
    },
    call: {
      users: {
        joined: '[{{username}}] a rejoint la transmission',
        left: '[{{username}}] a quitté la transmission',
      },
    },
    recording: {
      started: 'La vidéo a commencé à être enregistrée',
      stopped: 'La vidéo a arrêté d\'être enregistrée',
      error: 'Impossible de démarrer l\'enregistrement. Veuillez réessayer dans un instant.',
    },
  },
};
